import Styled from 'styled-components';

import { baseMixin, ctaButtonMixin } from '../mixins';

const Wrapper = Styled.div`
  ${baseMixin};
  ${ctaButtonMixin};
`;

export { Wrapper };
