import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';

import Layout from '../layouts/mainLayout';
import HeroImage from '../components/heroImage';
import Img from 'gatsby-image';

import { Wrapper } from '../styles/pages/diy-carpet-cleaning-tips';

import config from '../config';

export const query = graphql`
  query carpetCleaningTipsQuery {
    hero: file(relativePath: { regex: "/do-it-myself.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const hero = data => {
  return (
    <HeroImage
      backdrop={false}
      image={<Img fluid={data.hero.childImageSharp.fluid} />}
    />
  );
};

const DIYCarpetCleaningTipsPage = ({ data }) => {
  return (
    <Layout hero={hero(data)}>
      <Helmet>
        <meta
          name="description"
          content="Carpet cleaning tips that you can do yourself when needed."
        />
        <title>Carpet Cleaning Tips | Sci-Tech Carpet Cleaning</title>
      </Helmet>
      <Wrapper>
        <p>
          When the new dog has just made a mess on the carpet, you don’t always
          have time to grab the phone and schedule a carpet cleaning
          appointment. Sometimes you need to take matters into your own hands
          before a small problem becomes a stain that won’t come out.
        </p>
        <p>
          This guide aims to provide some easy solutions to common problems we
          face. Of course, if the particular issues you face aren’t listed here,
          feel free to <Link to="/contact">get in touch</Link> and we’ll be
          happy to answer any questions you might have.
        </p>
        <h2>Table Of Contents</h2>
        <ul className="toc">
          <li>
            <a href="#otc-spot-removers">
              What do I need to know about over-the-counter spot removers?
            </a>
          </li>
          <li>
            <a href="#fresh-stain">
              What should I do about a fresh spot or stain?
            </a>
          </li>
          <li>
            <a href="#use-store-bought-machine">
              Is it worth it to use a store-bought carpet cleaning machine?
            </a>
          </li>
          <li>
            <a href="#rug-doctor">Is it worth renting a Rug Doctor?</a>
          </li>
          <li>
            <a href="#pet-odors">What can I do about pet odors?</a>
          </li>
          <li>
            <a href="#water-damage">What can I do about water damage?</a>
          </li>
        </ul>
        <h2 id="otc-spot-removers">
          What do I need to know about over-the-counter spot removers? Are they
          safe? Are they effective?
        </h2>
        <p>
          Many over-the-counter spot removers are extremely effective. They
          aren’t always safe, however. I’m not going to single any product out,
          but I will say that using over the counter spot and stain removers
          comes with a certain risk.
        </p>
        <p>
          Sometimes the risk you might face will only be quicker re-soiling of
          the affected area. This happens because of residue left behind from
          the spot remover. The residue attracts dirt and quickly becomes a
          dirty spot on the carpet.
        </p>
        <p>
          Other times the risk you might face could be damage to the carpet.
          Many over-the-counter products are so concentrated that they can even
          damage the carpet. This damage might be in the form of color loss. It
          could also be in the form of fiber damage.
        </p>
        <p>
          If you’d like to have a go at a completely safe over-the-counter
          product, try these steps:
        </p>
        <ol>
          <li>
            Mix a few drops of a basic dish soap into a cup of warm water.
          </li>
          <li>Stir the solution to ensure a good mix.</li>
          <li>Pour the solution onto the spot.</li>
          <li>Allow the solution to dwell for a few minutes.</li>
          <li>
            Use paper towels or an old rag to blot the area to remove the excess
            solution.
          </li>
          <li>
            After you have blotted as much up as you can, then you can scrub the
            spot dry.
          </li>
        </ol>
        <p>
          This process is cheap, simple, safe, and extremely effective at
          removing nearly any fresh spot on the carpet.
        </p>
        <h2 id="fresh-stain">
          What should I do about a fresh spot or stain on my carpet?
        </h2>
        <p>
          When a spill or an accident occurs, it’s important to deal with it as
          quickly as possible. The first thing you always want to do is to
          remove as much of the substance as possible.
        </p>
        <p>
          After the bulk of the substance has been removed, apply the solution
          described in the section above generously to the spot. Follow the
          steps described in the section above to remove the spot from the
          carpet.
        </p>
        <p>
          In nearly all situations, this will be enough to completely remove the
          spot from your carpet if you get to it quickly enough. In the event
          that this doesn’t work,{' '}
          <a href={config.phoneNumberHref}>give us a call</a> and we can discuss
          it with you in more specific detail.
        </p>
        <h2 id="use-store-bought-machine">
          Is it worth using a store-bought carpet cleaning machine?
        </h2>
        <p>
          Absolutely. Anything you can do to maintain your carpets in between
          professional cleanings is a very good thing. We also recommend
          vacuuming often.
        </p>
        <p>
          One thing of note is that some of the cleaners used in these carpet
          cleaning machines can leave residue behind. This will cause problems
          with re-soiling causing your carpets to get dirty quicker. In the
          event that the cleaner you’re using is causing this, simply try a new
          cleaning product.
        </p>
        <h2 id="rug-doctor">Should I rent a Rug Doctor?</h2>
        <p>
          Probably not. The costs associated with renting a Rug Doctor can often
          begin to challenge the price of having your carpets professionally
          cleaned.
        </p>
        <p>
          Once you’ve purchased the cleaning materials and rented the equipment,
          it’s not uncommon to have a significant amount of money tied up in the
          project. Then you’ll be spending a large part of the rest of your day
          cleaning the carpet.
        </p>
        <p>
          Alternatively, having your carpets professionally cleaned typically
          only costs a bit more, is completed in a fraction of the time, and
          will be done to a much higher quality.
        </p>
        <h2 id="pet-odors">What can I do about pet odors?</h2>
        <p>
          Not much. Pet odors are notoriously difficult to deal with. The best
          way to deal with pet odors is to avoid them by dealing with pet
          accidents as soon as they happen.
        </p>
        <p>
          This isn’t always practical, though. If you do have some lingering pet
          odors, there are a few things you can try.
        </p>
        <ol>
          <li>
            Air the room with the pet odors out as much as possible. Opening
            windows is a great way to do this.
          </li>
          <li>
            Apply baking soda to the source of the odor if you know where it is.
            This will be the place where the pet accident occurred. If you don’t
            know the location of the pet accident, a black light can help to
            locate it.
          </li>
          <li>
            Allow the baking soda to sit for a few hours at least before
            vacuuming it up.
          </li>
          <li>Repeat this process as needed.</li>
        </ol>
        <p>
          If this isn’t cutting it and the pet spot is close to a wall, you can
          try pulling the carpet back to check for pet urine residue on the
          padding. If the padding has pet urine on it, it will probably need to
          be replaced.
        </p>
        <p>
          If none of these steps are working for you,{' '}
          <a href={config.phoneNumberHref}>give us a call</a>. We have some
          cheap solutions that are often effective at removing pet issues.
        </p>
        <h2 id="water-damage">What can I do about water damage?</h2>
        <p>
          Water damage is especially dangerous because you might have to deal
          with mold issues too. Because of this, I always recommend{' '}
          <a href={config.phoneNumberHref}>calling a professional</a> when you
          have water damage issues.
        </p>
        <p>
          If you insist on trying to handle it yourself, then it’s imperative
          that you dry the carpet as quickly as possible.
        </p>
        <p>
          This often requires pulling the carpet back away from the wall so the
          padding can dry. If you can get air flow across the carpet and
          padding, that will help too. Renting some snail fans from a local
          hardware store will make this process go much smoother.
        </p>
        <p>
          If the humidity levels aren’t too high outside, open windows to allow
          the moisture to escape. This will allow the carpet to dry quicker. If
          it appears that the carpet is going to take more than three days to
          dry, you definitely need to seek professional help dealing with the
          problem. The longer that carpet remains wet, the greater the risk of
          mold forming.
        </p>
        <hr />
        <span style={{ fontSize: '20px', float: 'right' }}>
          Want to learn more about professional carpet cleaning services?
          <br />
          <Link className="cta-button" to="/carpet-cleaning-information">
            Find out now
          </Link>
        </span>
        <div style={{ clear: 'both', display: 'table' }} />
      </Wrapper>
    </Layout>
  );
};

export default DIYCarpetCleaningTipsPage;
